import React, { useRef, useState } from "react";
import styled from "styled-components";
import { color, typography } from "../../../shared/style";
import PropTypes from "prop-types";
import { Text } from "../../../components/Text/Text";
import arrowleft from "../../../images/arrowleft.svg";
import arrowright from "../../../images/arrowright.svg";
import { isMobile } from "../../../hooks/checkMobile";
// import { RichText } from "prismic-reactjs";
// import htmlSerializer from "./RichtextHelper/CustomText";

export const Categories = ({ categories, onSelectedCategory }) => {
    const [menuActived, setActive] = useState(-1)
    const cate = useRef(null)
    let pos = { left: 0, x: 0 };
    const scrollBack = () => {
        cate.current.scrollLeft += -70;
    }
    const scrollNext = () => {
        cate.current.scrollLeft += 70;
    }
    const mouseDownHandler = (e) => {
        cate.current.style.userSelect = 'none';
        pos = {
            left: cate.current.scrollLeft,
            x: e.clientX,
        };
        cate.current.addEventListener('mousemove', mouseMoveHandler);
        cate.current.addEventListener('mouseup', mouseUpHandler);
        cate.current.addEventListener('mouseleave', mouseUpHandler);
    };
    const mouseUpHandler = () => {
        cate.current.removeEventListener('mousemove', mouseMoveHandler);
    };
    const mouseMoveHandler = (e) => {
        const dx = e.clientX - pos.x;
        cate.current.scrollLeft = pos.left - dx;
    };
    const onPressItem = (item, i, isAll) => {
        onSelectedCategory(item, isAll);
        setActive(i)
    }
    if (!categories) return null;
      return (
        <WrapperSection>
          <WrapperMenu ref={cate} onMouseDown={(e) => mouseDownHandler(e)}>
            <Item
              isAll={true}
              onClick={() => onPressItem([], -1, true)}
              isActive={menuActived === -1}
            >
              <Name
                tag="h5"
                color={color.netraul.black100}
                capital="uppercase"
                weight={typography.weight.regular1}
              >
                All
              </Name>
            </Item>
            <Divider />
            {categories?.map((item, i) => (
              <Item
                key={i}
                onClick={() => onPressItem(item, i)}
                isActive={menuActived === i}
              >
                <Name
                  tag="h5"
                  color={color.netraul.black100}
                  capital="uppercase"
                  weight={typography.weight.regular1}
                >
                  {item.category_name}
                </Name>
              </Item>
            ))}
          </WrapperMenu>
          {!isMobile && (
            <WrapperButton>
              <Btn onClick={() => scrollBack()}>
                <Icon src={arrowleft} />
              </Btn>
              <Btn onClick={() => scrollNext()}>
                <Icon src={arrowright} />
              </Btn>
            </WrapperButton>
          )}
        </WrapperSection>
      );
};

Categories.propTypes = {
    title: PropTypes.string,
};
const WrapperButton = styled.div`
    display: flex;
`
const Btn = styled.button`
    &:first-child {
        margin-right: 16px;
        margin-left: 30px;
    }
    &:active {
        background-color: ${color.netraul.black10};
        border: 1px solid ${color.netraul.black20};
    }
    padding: 23px;
    border: 1px solid ${color.netraul.black20};
    border-radius: 50%;
    background-color: white;
    width: 60px;
    height: 60px;
`
const Icon = styled.img`
    width: 14px;
    height: 14px;
`
const Divider = styled.div`
    width: fit-content;
    border-right: 1px solid #D8DADE;
    height: 36px;
`
const Name = styled(Text)`
    cursor: pointer;
    letter-spacing: -0.015em;
    white-space: nowrap;
`
const Item = styled.div`
    ${prop => prop.isAll && `
        margin-right: 40px!important;
        @media (max-width: 650px) {
           margin-right: 20px!important;
        }
    `}
    &:nth-child(3) {
        margin-left: 40px;
        @media (max-width: 650px) {
            margin-left: 22px;
        }
    }
    ${props => props.isActive && `
        {
            span {
                color: ${color.primary.blue};
            }
            transition: 0.3s;
            border-bottom: 4px solid ${color.primary.blue};
        }
    `} 
    margin-right: 70px;
    width: fit-content;
    align-items: center;
    display: flex;
    flex-direction: row;
    /* cursor: pointer; */
    padding-bottom: 13px;
    border-bottom: 4px solid ${color.primary.white};
    transition: 0.3s;
    &:hover {
        span {
            color: ${color.primary.blue};
        }
        transition: 0.3s;
        border-bottom: 4px solid ${color.primary.blue};
    }
    @media (max-width: 650px) {
        margin-right: 30px;
    }
`
const WrapperMenu = styled.div`
    scroll-behavior: smooth;
    cursor: pointer;
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    flex-wrap: nowrap;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }

`
const WrapperSection = styled.div`
    display: flex;
`;