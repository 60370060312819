import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import { Recent } from "./Recent"
import { Subscribe } from "./Subscribe"
import { Header } from "./Header"

export const TagsContainer = ({ data, tag, count, allData }) => {
  const [searchValue, setValueSearch] = useState("")
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.location.href = '/blog'
  }
  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);
  return (
    <WrapperLayout>
      <Header setValueSearch={(value) => setValueSearch(value)} tag={tag} countTag={count} />
      <Recent searchValue={searchValue} data={data} allData={allData} />
      <Subscribe />
    </WrapperLayout>
  )
};


const WrapperLayout = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
`;
