import React, { useMemo } from 'react'
import { ThemeProvider } from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Sidebar } from '../components/Sidebar';
import { TagsContainer } from "../containers/TagsContainer/TagsContainer";
import FooterContainer from '../containers/FooterContainer';
import { Header } from '../containers/HeaderContainer';
import useApp, { withAppContext } from '../contexts/context'
import { useSiteMetadata } from '../hooks/useQuerydata';
import { useQueryBlogCategories } from '../hooks/useQueryBlogCategories';
import { useQueryBlog } from '../hooks/useQueryBlogs';

const Tags = () => {
  const { toggle, handleToggleLayout } = useApp();
  const { allPrismicFooter, allPrismicHeader } = useSiteMetadata();
  const { allPrismicBlogCategories } = useQueryBlogCategories();
  const { allPrismicBlog, allPrismicBlogDetail } = useQueryBlog();
  const data = allPrismicBlogCategories.nodes;
  const isBrowser = () => typeof window !== "undefined";
  const tag = isBrowser() && decodeURIComponent(window.location.search.substring(5));
  const count = allPrismicBlogDetail?.group.find(item => item.fieldValue === tag)?.totalCount;
  const dataTag = useMemo(() => {
    return data.map(item => {
      return { category_name: item.data.category_name.text, posts: item.data.posts?.filter(ite => ite.post.tags.includes(tag)) }
    })
  }, [tag, data, ])
  const allData = useMemo(() => {
    return allPrismicBlogDetail?.nodes.filter(item => item?.tags?.includes(tag));
  }, [tag, allPrismicBlogDetail])
  const dataHeader = allPrismicHeader?.nodes[0]?.data;
  const theme = {
    bgColor: 'white',
  }
  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={
          <Header
            toggleLayout={handleToggleLayout}
            data={dataHeader}
          />
        }
        footer={
          <FooterContainer data={allPrismicFooter?.nodes[0]?.data} />
        }
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        seo={
          <SEO data={allPrismicBlog?.edges[0]?.node?.data?.body2} />
        }
      >
        <TagsContainer data={dataTag} tag={tag} count={count} allData={allData} />
      </Layout>
    </ThemeProvider>
  )
}

export default withAppContext(Tags);
