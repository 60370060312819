import { useStaticQuery, graphql } from "gatsby"

export const useQueryBlogCategories = () => {
  const { allPrismicBlogCategories } = useStaticQuery(
    graphql`
      query QueryBlogCategories {
        allPrismicBlogCategories {
            nodes {
              data {
                posts {
                  post {
                    uid
                    tags
                    document {
                      ... on PrismicBlogDetail {
                        data {
                          release_date
                          author {
                            html
                            text
                            raw
                          }
                          image {
                            url
                          }
                          title {
                            text
                            raw
                            html
                          }
                          open_new_tab
                        }
                      }
                    }
                  }
                }
                category_name {
                  text
                }
              }
            }
          }
      }
      `
  )

  return {
    allPrismicBlogCategories
  }
}