import React from "react";
import styled from "styled-components";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import { Text } from "../../components/Text/Text";
import search from "../../images/search.svg";
import { isMobile } from "../../hooks/checkMobile";
// import { RichText } from "prismic-reactjs";
// import htmlSerializer from "./RichtextHelper/CustomText";

export const Header = ({ tag, countTag, setValueSearch }) => {
  return (
    <WrapperSection>
      <Info>
        <WrapperHeadTitle>
          <HeadTitle
            tag={isMobile ? "h3" : "h4"}
            weight={500}
            capital="uppercase"
            color={color.primary.white}
          >
            STORIES AND NEWS FROM AROUND THE WORLD
          </HeadTitle>
        </WrapperHeadTitle>
        <WrapperTagged>
          <Text
            capital="uppercase"
            size={14}
            lineHeight={22}
            font={typography.type.primary}
            weight={typography.weight.normal}
            color={color.primary.blue40}
          >
            Tagged in
          </Text>
        </WrapperTagged>
        <WrapperInfo>
          <Tag
            type="h5"
            capital="uppercase"
            size={30}
            lineHeight={36}
            weight={typography.weight.regular1}
            color={color.primary.white}
          >
            {tag && "#" + tag}
          </Tag>

          <Text
            size={20}
            lineHeight={30}
            font={typography.type.primary}
            weight={typography.weight.normal}
            color={color.primary.blue40}
          >
            {countTag} Posts
          </Text>
        </WrapperInfo>
      </Info>
      <Action>
        <SearchBar>
          <InputSearch
            placeholder="Search for blog..."
            onChange={(e) => setValueSearch(e.target.value)}
          />
          <Icon src={search} />
        </SearchBar>
      </Action>
    </WrapperSection>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

const Icon = styled.img`
  width: 14px;
  height: 14px;
  right: 25px;
  top: 50%;
  position: absolute;
`;
const InputSearch = styled.input`
  margin-top: 12px;
  width: 100%;
  border: 1px solid #d3d3d4;
  border-radius: 50px;
  background-color: white;
  padding: 19px 50px 19px 22px;
  outline: none;
  font-size: 16px;
  box-sizing: border-box;
  line-height: 26px;
`;
const SearchBar = styled.div`
  position: relative;
  width: 100%;
`;

const Tag = styled(Text)`
  padding-right: 26px;
  border-right: 1px solid ${color.primary.blue60};
  margin-right: 26px;
  @media (max-width: 650px) {
  }
`;
const WrapperHeadTitle = styled.div`
  width: 60%;
  @media (max-width: 1455px) {
    width: 75%;
  }
  @media (max-width: 1217px) {
    width: 85%;
  }
  @media (max-width: 1105px) {
    width: 100%;
  }
  @media (max-width: 1000px) {
    width: 65%;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;
const WrapperInfo = styled.div`
  @media (max-width: 650px) {
  }
`;
const WrapperTagged = styled.div`
  letter-spacing: 0.2em;
  margin-top: 20px;
  margin-bottom: 10px;
  @media (max-width: 650px) {
    margin-top: 14px;
  }
`;
const HeadTitle = styled(Text)`
  letter-spacing: -0.015em;
`;
const Info = styled.div`
  width: 50%;
  @media (max-width: 650px) {
    width: 100%;
  }
`;
const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  @media (max-width: 650px) {
    width: 100%;
    margin-top: 40px;
  }
`;
const WrapperSection = styled.div`
  background-color: ${color.primary.blue};
  max-width: 1500px;
  padding: 0 130px;
  display: flex;
  padding-top: 140px;
  padding-bottom: 56px;
  flex-direction: row;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 650px) {
    padding-bottom: 70px;
    padding-left: 23px;
    padding-right: 23px;
    flex-direction: column;
  }
`;
