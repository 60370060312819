import React, { useState, useEffect } from "react";
import styled from "styled-components";
import snake from "../../images/snake.gif";
import PropTypes from "prop-types";
import { isMobile } from "../../hooks/checkMobile";
import { Categories } from "./Blogs/Categories";
import { ItemBlog } from "./Blogs/ItemBlog";

import { useDebounce } from '../../hooks/useDebounce';
import { Button } from "../../components/Button/Button";
// import { RichText } from "prismic-reactjs";
// import htmlSerializer from "./RichtextHelper/CustomText";

export const Recent = ({ data, searchValue, allData }) => {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(9);
  const [isEnd, setIsEnd] = useState(false);
  const [listData, setListData] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [dataShow, setDataShow] = useState([]);
  const [isLoading, setLoading] = useState(false);
  // const [valueSearch, setValueSearch] = useState("");
  const debouncedValue = useDebounce(searchValue, 800);

  useEffect(() => {
    const datas = [];
    data.forEach((item) => {
      item.posts.forEach((ite) => {
        datas.push(ite);
      });
    });
    setDataFiltered(allData);
    setListData(allData); // map data from list
    setData(allData); // update every page changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(dataFiltered); // update every page changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const onSelectedCategory = (item, isAll) => {
    setPage(1)
    isAll ? setData(allData) : setData(item.posts, true)
  }
  useEffect(() => {
    let dataFilter = []
    if (debouncedValue) {
      setPage(1)
      dataFilter = dataFiltered.filter((item) => {
        return item?.post?.document?.data?.title?.text.toLowerCase().includes(debouncedValue.toLowerCase()) || item?.data?.title?.text.toLowerCase().includes(debouncedValue.toLowerCase())
      })
      setData(dataFilter, true)
    } else setData(listData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  const setData = (data, force) => {
    let datas = [];
    let size = isMobile ? 3 : pageSize
    if (data.length || force) {
      datas = data.slice(0, page * size);
      setDataShow(datas);
      setIsEnd(page * size >= data.length)
    }
  };
  const onLoadMore = () => {
    setLoading(true)
    setTimeout(() => {
      setPage(page + 1);
      setLoading(false)
    }, 500);
  };
  if (!data) return null;
  return (
    <WrapperSection>
      <WrapperCategory>
        <Categories categories={data} onSelectedCategory={onSelectedCategory} />
      </WrapperCategory>
      <WrapperItem>
        {dataShow.length ? dataShow.map((ite, i) => (
          <ItemBlog key={i} data={ite} />
        )) : <NoData>No Data</NoData>}
      </WrapperItem>
      {dataShow.length && !isEnd ?
        <WrapperButton>
          <WrapperLoading>{isLoading && <Loading src={snake} />}</WrapperLoading>
          <Btn type="primary" onClick={() => onLoadMore()}>
            Load more
          </Btn>
        </WrapperButton>
        : null
      }
    </WrapperSection>
  );
};

Recent.propTypes = {
  title: PropTypes.string,
};
const NoData = styled.div`
  margin: 0 auto;
`
const WrapperLoading = styled.div`
  height: 30px;
  margin-top: 10px;
`
const Loading = styled.img`
  width: 30px;
`
const WrapperButton = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
`;
const Btn = styled(Button)`
  border-radius: 40px;
  padding: 20px 45px;
  line-height: 26px;
  width: fit-content;
  margin-top: 30px;
  @media (max-width: 650px) {
    margin-top: 40px;
  }
`;
const WrapperCategory = styled.div``;
const WrapperItem = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  @media (max-width: 1000px) and (min-width: 651px) {
    gap: 20px;
  }
`;

const WrapperSection = styled.div`
  padding: 0 130px;
  padding-top: 72px !important;
  max-width: 1500px;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    padding: 0 40px;
  }
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;
